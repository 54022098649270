.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: #10E956 !important;
    z-index: 100;
    max-width: 100%;
    height: 10vh;
    position: sticky;
    top: 0;
    animation: fadeInDown 1s ease forwards;
    @media (min-width: 760px) and (max-width: 1024px) {
        max-width: 100%;
    }
    @media (min-width: 480px) and (max-width: 759px) {
        max-width: 100%;
    }
    @media (max-width: 479px) {
        max-width: 100%;
    }
    .logo {
        cursor: pointer;
        position: fixed;
        padding: 0;
        z-index: 500;
        left: 5rem;
        top: 7px;
        @media (min-width: 1025px) and (max-width: 1300px){
            // width: 86px;
            // height: 87px;
            left: 5rem;
            top: 5px;
        }
        @media (min-width: 760px) and (max-width: 1024px) {
            // width: 79px;
            // height: 80px;
            left: 2rem;
            top: 6px;
        }
        @media (min-width: 480px) and (max-width: 759px) {
            // width: 70px;
            // height: 71px;
            left: 1.8rem;
            top: 9px;
        }
        @media (max-width: 479px) {
            // width: 60px;
            // height: 61px;
            left: 1.5rem;
            top: 9px;
        }
        object {
            width: 70px;
            height: 70px;
            @media (min-width: 1025px) and (max-width: 1300px){
                width: 65px;
                height: 65px;
            }
            @media (min-width: 760px) and (max-width: 1024px) {
                width: 60px;
                height: 60px;
            }
            @media (min-width: 480px) and (max-width: 759px) {
                width: 55px;
                height: 55px;
            }
            @media (max-width: 479px) {
                width: 55px;
                height: 55px;
            }
        }
      }
    .menu-toggle {
        transform: none !important;
        .nav__checkbox {
            display: none;
            &:checked ~ .navigation {
                opacity: 1;
                max-width: 100%;
                width: 100%;
                pointer-events: auto;
                .large_menu {
                    // animation: fadeIn 4s ease forwards 0s;
                    .github {
                        animation: zoomIn .5s ease forwards;
                        opacity: 1;
                        transition: opacity 0.5s;
                    }
                    .linkedin {
                        animation: zoomIn 1s ease forwards;
                        opacity: 1;
                        transition: opacity 1s;
                    }
                    .twitter {
                        animation: zoomIn 1.5s ease forwards;
                        opacity: 1;
                        transition: opacity 1.5s;
                    }
                    .schedule {
                        animation: zoomIn 2s ease forwards;
                        opacity: 1;
                        transition: opacity 2s;
                    }
                }
              }
              &:checked + .nav__button .nav__icon {
                background-color: transparent;
              }
              &:checked + .nav__button .nav__icon::before {
                transform: rotate(135deg);
                top: 0;
                width: 3.5rem;
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 2.5rem;
                    background-color: #10E956;
                  }
                  @media (max-width: 479px) {
                    width: 2.5rem;
                    background-color: #10E956;
                  }
              }
              &:checked + .nav__button .nav__icon::after {
                transform: rotate(-135deg);
                top: 0;
                width: 3.5rem;
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 2.5rem;
                    background-color: #10E956;
                  }
                  @media (max-width: 479px) {
                    width: 2.5rem;
                    background-color: #10E956;
                  }
              }
        }
        .nav__button {
            position: fixed;
            right: 5rem;
            top: 15px;
            height: 45px;
            width: 60px;
            z-index: 2000;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: wobble 1s ease forwards 10s;
            animation-iteration-count: 3;
            @media (min-width: 1025px) and (max-width: 1300px){
                width: 50px;
                height: 38px;
                padding: .8rem;
                right: 5rem;
            }
            @media (min-width: 760px) and (max-width: 1024px) {
                width: 42px;
                height: 32px;
                padding: .7rem;
                right: 2rem;
            }
            @media (min-width: 480px) and (max-width: 759px) {
                width: 40px;
                height: 30px;
                padding: .6rem;
                right: 1.8rem;
            }
            @media (max-width: 479px) {
                width: 35px;
                height: 25px;
                padding: .5rem;
                right: 1.5rem;
            }
            &:hover .nav__icon::before {
                top: -1rem;
            }
        
            &:hover .nav__icon::after {
                top: 1rem;
            }
            .nav__icon {
                position: relative;
                margin-right: -.8rem;
                width: 2.7rem;
                height: 8px;
                background-color: #1e2128;
                display: inline-block;
                transform: rotate(.5turn);
                border-radius: 4px;
                @media (min-width: 1025px) and (max-width: 1300px){
                    width: 2rem;
                    height: 8px;
                }
                @media (min-width: 760px) and (max-width: 1024px) {
                    width: 1.7rem;
                    height: 6px;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 1.5rem;
                    height: 6px;
                }
                @media (max-width: 479px) {
                    width: 1.3rem;
                    height: 6px;
                }
                &::before {
                    width: 2rem;
                    height: 8px;
                    background-color: #1e2128;
                    display: inline-block;
                    border-radius: 4px;
                    @media (min-width: 1025px) and (max-width: 1300px){
                        width: 3rem;
                        height: 8px;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        width: 2.7rem;
                        height: 6px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 2.3rem;
                        height: 6px;
                    }
                    @media (max-width: 479px) {
                        width: 2.1rem;
                        height: 6px;
                    }
                }
                &::after {
                    width: 3.5rem;
                    height: 8px;
                    background-color: #1e2128;
                    display: inline-block;
                    border-radius: 4px;
                    @media (min-width: 1025px) and (max-width: 1300px){
                        width: 3rem;
                        height: 8px;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        width: 2.7rem;
                        height: 6px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 2.3rem;
                        height: 6px;
                    }
                    @media (max-width: 479px) {
                        width: 2.1rem;
                        height: 6px;
                    }
                }
            
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    transition: all .2s;
                }
            
                &::before {
                   top: -.8rem;
                   @media (min-width: 480px) and (max-width: 759px) {
                    top: -.7rem;
                }
                @media (max-width: 479px) {
                    top: -.7rem;
                }
                }
            
                &::after {
                    top: .8rem;
                    @media (min-width: 480px) and (max-width: 759px) {
                        top: .7rem;
                    }
                    @media (max-width: 479px) {
                        top: .7rem;
                    }
                 }
            }
        }
        .navigation {
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            background: #14e956;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            width: 0;
            padding: 0;
            pointer-events: none;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 3fr;
            grid-template-areas: "box ."
                       "box menu";
            @media (min-width: 760px) {
                display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 3fr;
            grid-template-areas: "box ."
                       "box menu"
            }
            @media (min-width: 480px) and (max-width: 759px) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr;
                grid-template-areas: "menu"
                       "box";
            }
            @media (max-width: 479px) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr;
                grid-template-areas: "menu"
                       "box";
            }
            .large_menu {
                grid-area: box;
                background: #1e2128;
                height: 100%;
                width: 100%;
                display: flex;
                @media (min-width: 760px) {
                    max-width: 50vw;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 100vw !important;
                    height: 100% !important;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                @media (max-width: 479px) {
                    width: 100vw !important;
                    height: 100% !important;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .github {
                    width: 25%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    background: #333333;
                    color: #d6d6d6;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    opacity: 0;
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    @media (max-width: 479px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    &:hover, &:focus {
                        opacity: .7;
                    }
                    .github_box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (min-width: 480px) and (max-width: 759px) {
                            flex-direction: row;
                        }
                        @media (max-width: 479px) {
                            flex-direction: row;
                            width: 100%;
                            justify-content: space-between;
                        }
                    p {
                        transform: rotate(0.75turn);
                        font-size: 5.5rem;
                        font-weight: 600;
                        padding-right: 3rem;
                        @media (min-width: 1025px) and (max-width: 1300px){
                            font-size: 5.2rem;
                            padding-right: 2.7rem;
                        }
                        @media (min-width: 760px) and (max-width: 1024px) {
                            font-size: 4.6rem;
                            padding-right: 2.4rem;
                         }
                         @media (min-width: 480px) and (max-width: 759px) {
                            transform: rotate(0turn);
                            font-size: 3.5rem;
                            padding-left: 2rem;
                         }
                         @media (max-width: 479px) {
                            transform: rotate(0turn);
                            font-size: 2.5rem;
                            // padding-left: 2rem;
                         }
                    }
                    svg {
                        transform: rotate(0.75turn);
                        width: 85px;
                        height: 85px;
                        padding-left: 3rem;
                        @media (min-width: 1025px) and (max-width: 1300px){
                            width: 80px;
                            height: 80px;
                            padding-left: 2.7rem;
                        }
                        @media (min-width: 760px) and (max-width: 1024px) {
                            width: 70px;
                            height: 70px;
                            padding-left: 2.4rem;
                        }
                        @media (min-width: 480px) and (max-width: 759px) {
                            transform: rotate(0turn);
                            padding-right: 2rem;
                            width: 60px;
                            height: 60px;
                        }
                        @media (max-width: 479px) {
                            transform: rotate(0turn);
                            padding-right: 2rem;
                            width: 50px;
                            height: 50px;
                        }
                    }
                    }
                }
                .linkedin {
                    width: 25%;
                    height: 100%;
                    margin: 0;
                    background: #0077b5;
                    color: #cce4f0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    opacity: 0;
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    @media (max-width: 479px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    &:hover, &:focus {
                        opacity: .7;
                    }
                    .linkedin_box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (min-width: 480px) and (max-width: 759px) {
                            flex-direction: row;
                        }
                        @media (max-width: 479px) {
                            flex-direction: row;
                            width: 100%;
                            justify-content: space-between;
                        }
                        p {
                            transform: rotate(0.75turn);
                            font-size: 5.5rem;
                            font-weight: 600;
                            padding-right: 3rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                font-size: 5.2rem;
                                padding-right: 2.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                font-size: 4.6rem;
                                padding-right: 2.4rem;
                             }
                             @media (min-width: 480px) and (max-width: 759px) {
                                transform: rotate(0turn);
                                font-size: 3.5rem;
                                padding-left: 2rem;
                             }
                             @media (max-width: 479px) {
                                transform: rotate(0turn);
                                font-size: 2.5rem;
                                // padding-left: 2rem;
                             }
                        }
                        svg {
                            transform: rotate(0.75turn);
                            width: 85px;
                            height: 85px;
                            padding-left: 3rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                width: 80px;
                                height: 80px;
                                padding-left: 2.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                width: 70px;
                                height: 70px;
                                padding-left: 2.4rem;
                            }
                            @media (min-width: 480px) and (max-width: 759px) {
                                transform: rotate(0turn);
                                padding-right: 2rem;
                                width: 60px;
                                height: 60px;
                            }
                            @media (max-width: 479px) {
                                transform: rotate(0turn);
                                padding-right: 2rem;
                                width: 50px;
                                height: 50px;
                            }
                        }
                        }
                }
                .twitter {
                    width: 25%;
                    height: 100%;
                    margin: 0;
                    background: #1da1f2;
                    color: #d2ecfc;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    opacity: 0;
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    @media (max-width: 479px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    &:hover, &:focus {
                        opacity: .7;
                    }
                    .twitter_box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (min-width: 480px) and (max-width: 759px) {
                            flex-direction: row;
                        }
                        @media (max-width: 479px) {
                            flex-direction: row;
                            width: 100%;
                            justify-content: space-between;
                        }
                        p {
                            transform: rotate(0.75turn);
                            font-size: 5.5rem;
                            font-weight: 600;
                            padding-right: 3rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                font-size: 5.2rem;
                                padding-right: 2.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                font-size: 4.6rem;
                                padding-right: 2.4rem;
                             }
                             @media (min-width: 480px) and (max-width: 759px) {
                                transform: rotate(0turn);
                                font-size: 3.5rem;
                                padding-left: 2rem;
                             }
                             @media (max-width: 479px) {
                                transform: rotate(0turn);
                                font-size: 2.5rem;
                                // padding-left: 2rem;
                             }
                        }
                        svg {
                            transform: rotate(0.75turn);
                            width: 85px;
                            height: 85px;
                            padding-left: 3rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                width: 80px;
                                height: 80px;
                                padding-left: 2.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                width: 70px;
                                height: 70px;
                                padding-left: 2.4rem;
                            }
                            @media (min-width: 480px) and (max-width: 759px) {
                                transform: rotate(0turn);
                                padding-right: 2rem;
                                width: 60px;
                                height: 60px;
                            }
                            @media (max-width: 479px) {
                                transform: rotate(0turn);
                                padding-right: 2rem;
                                width: 50px;
                                height: 50px;
                            }
                        }
                        }
                }
                .schedule {
                    width: 25%;
                    height: 100%;
                    margin: 0;
                    background: #006cff;
                    color: #cce2ff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    opacity: 0;
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    @media (max-width: 479px) {
                        width: 100vw !important;
                        height: 5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                    &:hover, &:focus {
                        opacity: .7;
                    }
                    .schedule_box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (min-width: 480px) and (max-width: 759px) {
                            flex-direction: row;
                        }
                        @media (max-width: 479px) {
                            flex-direction: row;
                            width: 100%;
                            justify-content: space-between;
                        }
                        p {
                            transform: rotate(0.75turn);
                            font-size: 5.5rem;
                            font-weight: 600;
                            padding-right: 3rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                font-size: 5.2rem;
                                padding-right: 2.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                font-size: 4.6rem;
                                padding-right: 2.4rem;
                             }
                             @media (min-width: 480px) and (max-width: 759px) {
                                transform: rotate(0turn);
                                font-size: 3.5rem;
                                padding-left: 2rem;
                             }
                             @media (max-width: 479px) {
                                transform: rotate(0turn);
                                font-size: 2.5rem;
                                // padding-left: 2rem;
                             }
                        }
                        svg {
                            transform: rotate(0.75turn);
                            width: 85px;
                            height: 85px;
                            padding-left: 3rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                width: 80px;
                                height: 80px;
                                padding-left: 2.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                width: 70px;
                                height: 70px;
                                padding-left: 2.4rem;
                            }
                            @media (min-width: 480px) and (max-width: 759px) {
                                transform: rotate(0turn);
                                padding-right: 2rem;
                                width: 60px;
                                height: 60px;
                            }
                            @media (max-width: 479px) {
                                transform: rotate(0turn);
                                padding-right: 2rem;
                                width: 50px;
                                height: 50px;
                            }
                        }
                        }
                }
            }
            .small_menu {
                grid-area: menu;
                background: #1e2128;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
                @media (min-width: 480px) and (max-width: 759px) {
                    justify-content: center;
                }
                @media (max-width: 479px) {
                    justify-content: center;
                }
                ul {
                    padding-right: 3rem;
                    text-transform: uppercase;
                    @media (min-width: 480px) and (max-width: 759px) {
                        padding: 0;
                    }
                    @media (max-width: 479px) {
                        padding: 0;
                    }
                    li {
                        list-style: none;
                        margin: .7rem 0;
                        @media (min-width: 480px) and (max-width: 759px) {
                            margin: .9rem 0;
                        }
                        @media (max-width: 479px) {
                            margin: .9rem 0;
                        }
                        &:hover::before, &:active::before, &:focus::before, & .active::before {
                            content: " ";
                            display: inline-block;
                            background-color: transparent;
                            background-image: url('./../../assets/images/cursor.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 60px;
                            height: 60px;
                            margin-right: 2rem;
                            @media (min-width: 1025px) and (max-width: 1300px){
                                width: 52px;
                                height: 52px;
                                margin-right: 1.7rem;
                            }
                            @media (min-width: 760px) and (max-width: 1024px) {
                                width: 43px;
                                height: 43px;
                                margin-right: 1.4rem;
                            } 
                            @media (min-width: 480px) and (max-width: 759px) {
                                width: 34px;
                                height: 34px;
                                margin-right: 1.2rem;
                            }
                            @media (max-width: 479px) {
                                width: 34px;
                                height: 34px;
                                margin-right: 1.2rem;
                            }
                        } 
                        .nav-link {
                            text-decoration: none;
                            font-size: 4.5rem;
                            color: #f5f5f5;
                            font-weight: 900;
                            letter-spacing: 3px;
                            text-shadow:
                              3px  3px 0 #14e956,
                              -1px -1px 0 #14e956,  
                              1px -1px 0 #14e956,
                              -1px  1px 0 #14e956,
                              1px  1px 0 #14e956;
                            @supports (-webkit-text-stroke: .1px #14e956) {
                                  -webkit-text-stroke: .1px #14e956;
                                  -webkit-text-fill-color: transparent;
                              } 
                              @media (min-width: 1025px) and (max-width: 1300px){
                                font-size: 4rem;
                             }
                             @media (min-width: 760px) and (max-width: 1024px) {
                                font-size: 3rem;
                             } 
                             @media (min-width: 480px) and (max-width: 759px) {
                                font-size: 2.1rem;
                                letter-spacing: 4px;
                            text-shadow:
                              3px  3px 0 #12d24d,
                              -1px -1px 0 #12d24d,  
                              1px -1px 0 #12d24d,
                              -1px  1px 0 #12d24d,
                              1px  1px 0 #12d24d;
                            @supports (-webkit-text-stroke: .1px #14e956) {
                                  -webkit-text-stroke: .1px #14e956;
                                  -webkit-text-fill-color: transparent;
                              }
                            } 
                            @media (max-width: 479px) {
                                font-size: 2.1rem;
                                letter-spacing: 4px;
                            text-shadow:
                              3px  3px 0 #12d24d,
                              -1px -1px 0 #12d24d,  
                              1px -1px 0 #12d24d,
                              -1px  1px 0 #12d24d,
                              1px  1px 0 #12d24d;
                            @supports (-webkit-text-stroke: .1px #14e956) {
                                  -webkit-text-stroke: .1px #14e956;
                                  -webkit-text-fill-color: transparent;
                              }
                            } 
                        }
                    }
                }
            }
        } 
    }
}

@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
    //   transform: scale3d(0.3, 0.3, 0.3);
    }
  
    100% {
      opacity: 1;
    }
  }

  @keyframes wobble {
    from {
      transform: translate3d(0, 0, 0);
    }
  
    15% {
      transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
  
    30% {
      transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
  
    45% {
      transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
  
    60% {
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
  
    75% {
      transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }