footer {
    width: 100%;
    margin: 0;
    .social {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        padding: 0;
        margin-top: 5rem;
        .social-title {
            align-self: center;
            margin-bottom: 4.5rem;
            transform: rotate(0.01turn);
            @media (min-width: 1025px) and (max-width: 1300px) {
                margin-bottom: 4.3rem;
             }
             @media (min-width: 760px) and (max-width: 1024px) {
                margin-bottom: 4rem;
            }
            @media (min-width: 480px) and (max-width: 759px) {
                margin-bottom: 3.6rem;
            }
            @media (max-width: 479px) {
                margin-bottom: 3.3rem;
            }
            h2 {
                text-transform: uppercase;
                font-family: "Cyborg";
                font-size: 3rem;
                font-weight: 900;
                color: #14E956;
                margin: 0;
                opacity: .9;
                text-shadow:
                 3px  3px 0 #14E956,
                 -1px -1px 0 #14E956,  
                 1px -1px 0 #14E956,
                 -1px  1px 0 #14E956,
                 1px  1px 0 #14E956;
                 @supports (-webkit-text-stroke: .4px #000) {
                     -webkit-text-stroke: .4px #000;
                     -webkit-text-fill-color: #14E956;
                 }
                 @media (min-width: 1025px) and (max-width: 1300px) {
                    font-size: 2.5rem;
                 }
                 @media (min-width: 760px) and (max-width: 1024px) {
                    font-size: 2.2rem;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    font-size: 2rem;
                }
                @media (max-width: 479px) {
                    font-size: 1.8rem;
                }
            }
        }
        .social-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @media (min-width: 760px) and (max-width: 1024px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            @media (min-width: 480px) and (max-width: 759px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            @media (max-width: 479px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            .github-box {
                width: 25%;
                background: #333333;
                color: #d6d6d6;
                height: 9rem;
                padding: 0 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                position: relative;
                @media (min-width: 760px) and (max-width: 1024px) {
                    width: 50%;
                    padding: 0;
                    justify-content: space-around;
                    height: 6rem;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 100%;
                    padding: 0 3rem;
                    justify-content: space-between;
                    height: 5rem;
                }
                @media (max-width: 479px) {
                    width: 100%;
                    padding: 0 1.5rem;
                    justify-content: space-between;
                    height: 4.5rem;
                }
                &:hover, &:focus {
                    opacity: .7;
                }
                p {
                    font-size: 3.5rem;
                    font-weight: 600;
                    margin: 0;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        font-size: 3.1rem;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        font-size: 2.9rem;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        font-size: 2.6rem;
                    }
                    @media (max-width: 479px) {
                        font-size: 2.1rem;
                    }
                }
                svg {
                    width: 85px;
                    height: 85px;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        width: 75px;
                        height: 75px;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        width: 65px;
                        height: 65px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 55px;
                        height: 55px;
                    }
                    @media (max-width: 479px) {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            .linkedin-box {
                width: 25%;
                background: #0077b5;
                color: #cce4f0;
                height: 9rem;
                padding: 0 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                @media (min-width: 760px) and (max-width: 1024px) {
                    width: 50%;
                    padding: 0;
                    justify-content: space-around;
                    height: 6rem;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 100%;
                    padding: 0 3rem;
                    justify-content: space-between;
                    height: 5rem;
                }
                @media (max-width: 479px) {
                    width: 100%;
                    padding: 0 1.5rem;
                    justify-content: space-between;
                    height: 4.5rem;
                }
                &:hover, &:focus {
                    opacity: .7;
                }
                p {
                    font-size: 3.5rem;
                    font-weight: 600;
                    margin: 0;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        font-size: 3.1rem;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        font-size: 2.9rem;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        font-size: 2.6rem;
                    }
                    @media (max-width: 479px) {
                        font-size: 2.1rem;
                    }
                }
                svg {
                    width: 85px;
                    height: 85px;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        width: 75px;
                        height: 75px;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        width: 65px;
                        height: 65px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 55px;
                        height: 55px;
                    }
                    @media (max-width: 479px) {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            .twitter-box {
                width: 25%;
                background: #1da1f2;
                color: #d2ecfc;
                height: 9rem;
                padding: 0 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                @media (min-width: 760px) and (max-width: 1024px) {
                    width: 50%;
                    padding: 0;
                    justify-content: space-around;
                    height: 6rem;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 100%;
                    padding: 0 3rem;
                    justify-content: space-between;
                    height: 5rem;
                }
                @media (max-width: 479px) {
                    width: 100%;
                    padding: 0 1.5rem;
                    justify-content: space-between;
                    height: 4.5rem;
                }
                &:hover, &:focus {
                    opacity: .7;
                }
                p {
                    font-size: 3.5rem;
                    font-weight: 600;
                    margin: 0;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        font-size: 3.1rem;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        font-size: 2.9rem;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        font-size: 2.6rem;
                    }
                    @media (max-width: 479px) {
                        font-size: 2.1rem;
                    }
                }
                svg {
                    width: 85px;
                    height: 85px;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        width: 75px;
                        height: 75px;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        width: 65px;
                        height: 65px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 55px;
                        height: 55px;
                    }
                    @media (max-width: 479px) {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
            .schedule-box {
                width: 25%;
                background: #006cff;
                color: #cce2ff;
                height: 9rem;
                padding: 0 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                @media (min-width: 760px) and (max-width: 1024px) {
                    width: 50%;
                    padding: 0;
                    justify-content: space-around;
                    height: 6rem;
                }
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 100%;
                    padding: 0 3rem;
                    justify-content: space-between;
                    height: 5rem;
                }
                @media (max-width: 479px) {
                    width: 100%;
                    padding: 0 1.5rem;
                    justify-content: space-between;
                    height: 4.5rem;
                }
                &:hover, &:focus {
                    opacity: .7;
                }
                p {
                    font-size: 3.5rem;
                    font-weight: 600;
                    margin: 0;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        font-size: 3.1rem;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        font-size: 2.9rem;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        font-size: 2.6rem;
                    }
                    @media (max-width: 479px) {
                        font-size: 2.1rem;
                    }
                }
                svg {
                    width: 85px;
                    height: 85px;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        width: 75px;
                        height: 75px;
                    }
                    @media (min-width: 760px) and (max-width: 1024px) {
                        width: 65px;
                        height: 65px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        width: 55px;
                        height: 55px;
                    }
                    @media (max-width: 479px) {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
        }
        .social-mail {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 4rem;
            background: #10ba46;
            cursor: pointer;
            @media (min-width: 1025px) and (max-width: 1300px) {
                height: 4rem;
             }
             @media (min-width: 480px) and (max-width: 759px) {
                height: 3.7rem;
             }
             @media (max-width: 479px) {
                height: 3.5rem;
            }
            &:hover, &:focus {
                opacity: .7;
            }
            .box {
                height: 100%;
                width: 23%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (min-width: 480px) and (max-width: 759px) {
                    width: 50%;
                }
                @media (max-width: 479px) {
                    width: 100%;
                    padding: 0 1.5rem;
                }
                p {
                    margin: 0;
                    margin-bottom: -.3rem;
                    font-family: "Cyborg";
                    font-size: 2.2rem;
                    font-weight: 900;
                    color: #d0fbdd;
                    text-transform: uppercase;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        font-size: 1.8rem;
                     }
                     @media (min-width: 760px) and (max-width: 1024px) {
                        font-size: 1.4rem;
                     }
                     @media (min-width: 480px) and (max-width: 759px) {
                        font-size: 1.7rem;
                     }
                     @media (max-width: 479px) {
                        font-size: 1.5rem;
                    }
                }
                .mail {
                    height: 50px;
                    width: 50px;
                    margin: 0;
                    margin-top: -.3rem;
                    @media (min-width: 1025px) and (max-width: 1300px) {
                        height: 40px;
                        width: 40px;
                     }
                     @media (min-width: 760px) and (max-width: 1024px) {
                        height: 30px;
                        width: 30px;
                    }
                    @media (min-width: 480px) and (max-width: 759px) {
                        height: 38px;
                        width: 38px;
                    }
                    @media (max-width: 479px) {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
    }
    .footer {
        background: #1f2128;
        height: max-content;
        width: 100%;
        // margin-top: -1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            font-size: 1.4rem;
            margin: 0;
            font-family: "Cyborg";
            font-weight: 900;
            color: #10ba45;
            text-transform: capitalize;
            padding: 2rem 0;
            @media (min-width: 1025px) and (max-width: 1300px) {
                font-size: 1.2rem;
             }
             @media (min-width: 760px) and (max-width: 1024px) {
                font-size: 1.1rem;
             }
             @media (min-width: 480px) and (max-width: 759px) {
                font-size: .9rem;
                text-align: center;
                padding: .9rem .3rem;
            }
            @media (max-width: 479px) {
                font-size: .9rem;
                text-align: center;
                padding: .7rem .2rem;
            }
            span {
                animation: heartBeat 2.5s infinite;
                display: inline-block;
            }
        }
    }
}

@keyframes heartBeat {
    0% {
      transform: scale(1);
    }
  
    14% {
      transform: scale(1.3);
    }
  
    28% {
      transform: scale(1);
    }
  
    42% {
      transform: scale(1.3);
    }
  
    70% {
      transform: scale(1);
    }
  }